import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LibraryModule } from './library/library.module';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql.module';
import { DocumentUploadWarningPopupComponent } from './shared/components/document-upload-warning-popup/document-upload-warning-popup.component';
import { FaqComponent } from './shared/components/faq/faq.component';
import { TermsConditionsComponent } from './shared/components/terms-conditions/terms-conditions.component';
import { MarkdownModule } from 'ngx-markdown';
import { VersionComponent } from './shared/components/version/version.component';
import { NgxContentfulRichTextModule } from 'ngx-contentful-rich-text';
import { RegistrationSuccessComponent } from './shared/components/registration-success/registration-success.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DocumentUploadWarningPopupComponent,
    RegistrationSuccessComponent,
    FaqComponent,
    TermsConditionsComponent,
    VersionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LibraryModule,
    GraphQLModule,
    MarkdownModule.forRoot(),
    NgxContentfulRichTextModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
