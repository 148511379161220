<div class="faq-section-container">
    <div class="faq-section-content">
        <div class="faq-section-header" [ngStyle]="{'background-image': 'url(' + backgroundURL + ')'}">
            <div class="faq-title">{{FaqCMSData?.fqaTitle}}</div>
        </div>

        <div class="faq-container">
            <div *ngFor="let section of getFaQDetails; let i = index" class="faq-section">
                 <!-- comment code is as per generic Design Do not Delete-->
                <!-- <div class="faq-header" (click)="toggleSection(i)">
                    <div class="faq-header-title">{{ section.sectionTitle }}</div>
                    <span class="toggle-icon" *ngIf="openSections[i]">
                        <img src="assets/images/icons/collapse_outline_white.png" height="8px" width="8px">
                    </span>
                    <span class="toggle-icon" *ngIf="!openSections[i]">
                        <img src="assets/images/icons/expand_outline_white.png" height="8px" width="8px">
                    </span>
                </div>
        
                <div *ngIf="openSections[i]" class="faq-content">
                    <div *ngFor="let item of section.tnCFaqQnA" class="faq-item">
                        <div class="bullet"></div>
                        <div class="faq-text">
                            <div class="faq-text-question">{{ item.fields.genericQuestion }}</div>
                            <div class="faq-text-answer">{{ item.fields.genericAnswer }}</div>
                        </div>
                    </div>
                </div> -->

                <div class="faq-content">
                    <ngx-contentful-rich-text class="faq-header-title" [document]="FaqCMSData.faqHeaderContent"></ngx-contentful-rich-text>

                    <div *ngFor="let term of FaqCMSData.faqList" class="faq-text">
                        <div class="faq-text-question">{{term?.fields.heading}}</div>
                        <ngx-contentful-rich-text class="faq-text-answer" [document]="term?.fields.termsContent"></ngx-contentful-rich-text>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>