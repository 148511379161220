import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.less'
})
export class FooterComponent implements OnInit {

  @Input() headerFooterCMSData: any;
  getGenericSettings: any;

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getGenericSettings = JSON.parse(localStorage.getItem('portalGenericSettings') || '{}');
  }

  navigateTo(page: any) {
    switch (page) {
      case 'faq':
        this.router.navigate(['/faq']);
        break;
      case 'tnc':
        this.router.navigate(['/tnc']);
    }
  }
}
