import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication.service';
import { LoginService } from '../../../screens/login/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.less'
})
export class HeaderComponent implements OnInit {

  @Input() headerFooterCMSData: any;
  currentUser: any;
  isLogin: boolean = false;
  profileuUrl : string = 'assets/images/icons/header_person_logo.png';
  sponsorLogo: any;
  notificationIcon: any;
  settingsIcon: any;
  getGenericSettings: any;

  constructor(
    private authenticationService: AuthenticationService, 
    private router:Router,
  ) {
    this.profileuUrl = this.authenticationService.currentUser.value.profile_image ? this.authenticationService.currentUser.value.profile_image : 'assets/images/icons/header_person_logo.png';
  }

  ngOnInit(): void {
    this.getGenericSettings = JSON.parse(localStorage.getItem('portalGenericSettings') || '{}');
    this.sponsorLogo = 'https:' +  this.headerFooterCMSData?.sponsorLogo?.fields.file.url;
    this.notificationIcon = 'https:' +  this.headerFooterCMSData?.notificationIcon?.fields.file.url;
    this.settingsIcon = 'https:' +  this.headerFooterCMSData?.settingsIcon?.fields.file.url;
    this.authenticationService.currentUser.subscribe(data => {
      this.currentUser = data;
      if(this.currentUser && (this.currentUser.token)) {
        this.isLogin = true;
        this.profileuUrl = this.authenticationService.currentUser.value.profile_image ? this.authenticationService.currentUser.value.profile_image : 'assets/images/icons/header_person_logo.png';
      } else {
        this.isLogin = false;
      }
    });
  }

  logout() {
    this.authenticationService.logoutUser();
  }

  routeToLogin() {
    if(this.isLogin) {
      this.router.navigate(['/offers']);
    } else {
      this.router.navigate(['/home']);
    }
  }

}
