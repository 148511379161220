<div class="page-container">
  <app-header class="header" *ngIf="showHeader && headerFooterCMSData" [headerFooterCMSData]="headerFooterCMSData"></app-header>

  <div class="main-content" [ngClass]="{'main-content--no-default-header':!(showFooter && showHeader),'main-content--show-sidebar':showSideStepper}">
    <aside *ngIf="showSideStepper" class="side-stepper-container">
      <app-side-stepper [activeStep]="activeStep"></app-side-stepper>
    </aside>
    <main class="content" [ngClass]="{'content--no-side-bar':!showSideStepper}">
      <router-outlet></router-outlet> <!-- Main content will be rendered here -->
    </main>
  </div>

  <app-footer class="footer" *ngIf="showFooter && headerFooterCMSData" [headerFooterCMSData]="headerFooterCMSData"></app-footer>
</div>