import {
  CanActivateFn,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { LoginService } from '../../screens/login/login.service';
import { DocumentUploadWarningPopupComponent } from '../components/document-upload-warning-popup/document-upload-warning-popup.component';
import { RegistrationSuccessComponent } from '../components/registration-success/registration-success.component';
import { MatDialog } from '@angular/material/dialog';

export const authGuard =
  (isProtected: boolean): CanActivateFn =>
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router: Router = inject(Router);
    const authenticationService = inject(AuthenticationService);
    const loginService = inject(LoginService);
    const dialog: MatDialog = inject(MatDialog);
    // const protectedRoutes: string[] = ['/locations','/offers','/profile'];
    // Initialize getCurrentUser as false by default
    let isAuthenticated = false;
    let passwordScNumber: number | null = null;

    // Check if the platform is a browser and localStorage is available

      const storedUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
      isAuthenticated = !!storedUser && (storedUser.token || storedUser["Two-FA-Token"]); // Ensure getCurrentUser is true if user exists and has a token

      const tenantDetail = JSON.parse(localStorage.getItem('tenantInfo') || '{}');
      const isTwofaSet = JSON.parse(localStorage.getItem('is_2fa_setup_completed') || '{}');
      const isTotpView = JSON.parse(localStorage.getItem('showTOTPSection') || '{}');
      const pwdScreenNo = JSON.parse(localStorage.getItem('password_screen_number') || '{}');
      const sponsorDetails = JSON.parse(localStorage.getItem('sponsorDetails') || '{}');
      const sponsorDocuments = JSON.parse(localStorage.getItem('sponsorDocuments') || '{}');

    if (isAuthenticated && !isProtected) {

      if((sponsorDetails.status == 'Q' || sponsorDetails.status == 'P') && sponsorDocuments.documents.length == 0) {
        dialog.open(DocumentUploadWarningPopupComponent,{
          backdropClass: 'backdropBackground',
          panelClass: 'custom-dialog-container',
          width: '680px',
          disableClose: true,
        });
      } else if((sponsorDetails.status == 'Q' || sponsorDetails.status == 'P') && sponsorDocuments.documents.length != 0) {
        dialog.open(RegistrationSuccessComponent,{
          backdropClass: 'backdropBackground',
          panelClass: 'custom-dialog-container',
          width: '680px',
          disableClose: true,
        });
      } else if((sponsorDetails.status == 'A') && sponsorDocuments.documents.length == 0) {
        dialog.open(DocumentUploadWarningPopupComponent,{
          backdropClass: 'backdropBackground',
          panelClass: 'custom-dialog-container',
          width: '680px',
          disableClose: true,
        });
      } else if((sponsorDetails.status == 'A') && sponsorDocuments.documents.length != 0) {
        router.navigate(['/offers']);
      }
      
      if(isTotpView) {
        authenticationService.logoutUser();
        return false;
      }

      if(tenantDetail['2fa_status'] == 'MANDATORY' && isTwofaSet == false) {
        authenticationService.logoutUser();
        return false;
      } else if(tenantDetail['2fa_status'] == 'OPTIONAL' && storedUser.is_2fa_setup) {
        authenticationService.logoutUser();
        return false;
      } else if(tenantDetail['2fa_status'] == 'OPTIONAL') {
        router.navigate(['/offers']);
        return true;
      }

      if(storedUser.is_2fa_setup && isTwofaSet) {
        router.navigate(['/offers']);
      } else {
        authenticationService.logoutUser();
        return false;
      }

    } else if (!isAuthenticated && isProtected) {
      router.navigate(['/login']);
    } else if(!isAuthenticated && !isProtected && pwdScreenNo != 0) {
      localStorage.setItem('password_screen_number', '0');
      router.navigate(['/login']);
      return false;
    } else if(isAuthenticated && isProtected && !storedUser.is_2fa_setup) {
      router.navigate(['/login']);
    }else if(isAuthenticated && isProtected && !isTwofaSet){
      router.navigate(['/login']);
    }else {
      return true;
    }
    router.navigate(['/home']);
    return false;
  };
