<div class="header-container">
    <div class="header-content">
        <div class="header-left-content">
            <img class="sponsor-image" [src]='sponsorLogo' alt="sponsor-logo" (click)="routeToLogin()">
            <div class="sponsor-header">{{headerFooterCMSData?.sponsorName}}</div>
            <div class="powered-header">{{headerFooterCMSData?.poweredByLabel}}</div>
            <div class="gravty-header">{{headerFooterCMSData?.gravtyLabel}}</div>
        </div>
        <div class="header-right-content" *ngIf="isLogin">
            <span *ngIf="getGenericSettings?.showHeaderNotificationIcon"><img class="bell-icon" [src]='notificationIcon' alt="bell-icon"></span>
            <span *ngIf="getGenericSettings?.showHeaderSettingsIcon"><img class="settings-icon" [src]='settingsIcon' alt="settings-icon"></span>
            <span (click)="logout()"><img class="profile-icon" [src]="profileuUrl" alt="profile-icon"></span>
        </div>
    </div>
</div>
