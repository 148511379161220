<div class="footer-container">
    <div class="footer-content">
        <div class="footer-left-content">
            <div class="nav-container">
                <div class="nav-item" *ngIf="getGenericSettings?.showContactNavigation">
                    <markdown id="nav-content" class="text-here" [data]="headerFooterCMSData?.contactCta"></markdown>
                </div>
                <div class="nav-item" *ngIf="getGenericSettings?.showPrivacyNavigation">
                    <markdown id="nav-content" class="text-here" [data]="headerFooterCMSData?.privacyCta"></markdown>
                </div>
                <div class="nav-item" *ngIf="getGenericSettings?.showFaqNavigation">
                    <markdown id="nav-content" class="text-here" [data]="headerFooterCMSData?.faqCta"></markdown>
                </div>
                <div class="nav-item" *ngIf="getGenericSettings?.showTnCNavigation">
                    <markdown id="nav-content" class="text-here" [data]="headerFooterCMSData?.tnCCta"></markdown>
                </div>
                <div class="nav-item" *ngIf="getGenericSettings?.showSitemapNavigation">
                    <markdown id="nav-content" class="text-here" [data]="headerFooterCMSData?.sitemapCta"></markdown>
                </div>
            </div>
        </div>
        <div class="footer-right-content">
            <div class="copyright-text">{{headerFooterCMSData?.copyrightText}}</div>
        </div>
    </div>
</div>
