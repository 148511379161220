import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../../../services/contentful.service';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.less',
})
export class FaqComponent implements OnInit {

  FaqCMSData: any;
  locale = 'en-US';
  labelsCMSModelData: any;
  FaqData: any = [];
  contentFulIds: any = [];
  getFaQIds: any = [];
  getFaQDetails: any = [];
  openSections = [true, false];
  backgroundURL: any;

  constructor(
    private contentfulService: ContentfulService,
    private sharedService: SharedService,
  ) {

  }

  ngOnInit(): void {
    this.backgroundURL = 'assets/images/img/partner_header_bg.png';
    this.getFaqCMSData();
  }

  toggleSection(index: number): void {
    this.openSections[index] = !this.openSections[index];
  }

  getFaqCMSData() {
    this.sharedService.toggleGlobalLoader(true);
    this.contentfulService
      .getContent('2Inrc4GEzsvSsoz1EFXAJU', this.locale)
      .subscribe((data: any) => {
        this.FaqCMSData = data;
        this.backgroundURL = this.FaqCMSData.programFaqBackgroundImage.fields.file.url;
        this.FaqData = this.FaqCMSData.programFaqSection;
        this.FaqData.forEach((element: any) => {
          this.getFaQIds.push(element.sys.id);
        });

        this.getAllFaQData(this.getFaQIds);
        this.sharedService.toggleGlobalLoader(false);
      });
  }

  getAllFaQData(contentIDs: any) {
    this.sharedService.toggleGlobalLoader(true);
    contentIDs.forEach((ele: any) => {
      this.contentfulService
        .getContent(ele, this.locale)
        .subscribe((data: any) => {
          this.getFaQDetails.push(data);
          this.sharedService.toggleGlobalLoader(false);
        });
    });
  }

}
